import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const ProgramSuccess = () => {
  return (
    <div>
      <FormSuccessTemplate
        title="Succès - Demande d'information d'un programme"
        description="Votre demande d'information pour un programme a bien été envoyée !" />
    </div>
  )
}

export default ProgramSuccess
